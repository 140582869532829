import { useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import { useFormContext } from 'react-hook-form'
import { TextField, Select, Subtitle, Title } from 'components'
import { useGlobalData } from '../../../../hooks/api'
import styles from './styles.module.scss'

const residenceTypeOptions = [
  {
    label: 'Residencial',
    value: 'Residencial',
  },
  {
    label: 'Comercial',
    value: 'Comercial',
  },
  {
    label: 'Industrial',
    value: 'Industrial',
  },
  {
    label: 'Rural',
    value: 'Rural',
  }
]

const classificationOptions = [
  {
    label: 'Monofásico',
    value: 'Monofásico',
  },
  {
    label: 'Bifásico',
    value: 'Bifásico',
  },
  {
    label: 'Trifásico',
    value: 'Trifásico',
  },
]

export function EnergyProviderForm({ generateOptions }: any) {
  const [options, setOptions] = useState([])
  const [disableFields, setDisableFields] = useState<boolean>(false)
  const { setValue, watch, formState } = useFormContext()
  const { customer, billElegibility } = useGlobalData()
  const eligibility = billElegibility || customer?.elegibilidade
  const concessionaire = watch('concessionaria')
  const errors = formState.errors
  
  useEffect(() => {
   if (_isEmpty(errors) && eligibility) {
    return setDisableFields(true)
   }

   return setDisableFields(false)
  }, [errors, eligibility])

  const state: 'MG' | 'MT' | 'MS' | 'RJ' | 'SP' | 'GO' | 'PE' =
    watch('ufconsumo')

  useEffect(() => {
    if (!state) return 

    setOptions(generateOptions(state))
  }, [])

  useEffect(() => {
    if (
      !options?.length ||
      _some(options, { value: concessionaire })
    )
      return

    setValue('concessionaria', '')
  }, [options])

  return (
    <div className={styles.container}>
      <Title>
        Agora precisamos conhecer mais sobre a empresa que lhe fornece energia
        atualmente
      </Title>
      <Subtitle>
        Pegue a sua conta de luz e preencha as informações abaixo, se tiver
        alguma dúvida é só consultá-la.
      </Subtitle>
      <Select
        name="concessionaria"
        label="Distribuidora de energia"
        options={options}
        required
        disabled      
      />
      <Select
        name="classificacao"
        label="Selecione o tipo da sua instalação"
        options={residenceTypeOptions}
        required
        disabled={disableFields}
      />
      <Select
        name="energyBox"
        label="Selecione o tipo do seu padrão"
        options={classificationOptions}
        required
        disabled={disableFields}
      />
      <TextField
        name="valorkwh"
        label="Valor Kwh"
        mask="0,00"
        required
        disabled={disableFields}
      />
      <TextField
        name="numcliente"
        label="Número Cliente"
        required
        disabled={disableFields}
      />
      <TextField
        name="valorcontribuicao"
        mask={[
          {
            mask: '0,00',
          },
          {
            mask: '00,00',
          },
          {
            mask: '000,00',
          },
        ]}
        label="Taxa Ilum. Pública"
        required
        disabled={disableFields}
      />
      <TextField
        name="numinstalacao"
        label="Número Instalação"
        required
        disabled={disableFields}
      />
    </div>
  )
}
