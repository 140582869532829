import * as z from "zod";

import { lessUnderscore } from "utils";

export const schemaCompanyAddressData = z.object({
  emp_cep: z.preprocess(
    lessUnderscore,
    z.string().length(10, "CEP Obrigatório")
  ),
  emp_complemento: z.string().optional(),
  emp_endereco: z.string().min(3, "Endereço obrigatório"),
  emp_numero: z.string().min(1, "Número obrigatório"),
  emp_bairro: z.string().min(1, "Bairro obrigatório"),
  emp_cidade: z.string().min(3, "Cidade obrigatória"),
  emp_uf: z.string().length(2, "UF obrigatório"),
});
