import { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";

import styles from "./styles.module.scss";
import { RadioGroup, Subtitle, TextField, Title } from "components";
import {
  ConfigOption,
  Option,
} from "features/connection-form/ConnectionForm.types";

import { calculateIfConsumptionIsAllowed, getLast12Months } from "./";
import { useGlobalData } from "../../../../hooks/api";
import { FormLayout, Text } from "../account-state-form/styles";
import { Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const ErrorMessage = styled.span`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
`;

const months = getLast12Months();

const energyBoxAvailability = {
  Monofásico: 30,
  Bifásico: 50,
  Trifásico: 100,
};

type EnergyCalcFormProps = {
  generateRangeValues: (state: string, company: string) => ConfigOption[];
  generateDiscountOptions: (
    state: string,
    company: string,
    averageConsumption: number
  ) => Option[];
};

export function EnergyCalcForm({
  generateRangeValues,
  generateDiscountOptions,
}: EnergyCalcFormProps) {
  const [discountOptions, setDiscountOptions] = useState<Array<Option>>([]);
  const { customer, averageConsumption: averageConsumptionGlobal } =
    useGlobalData();
  const {
    watch,
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const discountFromUrl = searchParams.get("desc") || "";

  const state = watch("ufconsumo");
  const powerCompany = watch("concessionaria");
  const powerSupplier = watch("fornecedora");
  const averageConsumption = Number(watch("consumomedio"));

  const energyBox: "Monofásico" | "Bifásico" | "Trifásico" = watch("energyBox");

  const monthsValues = months
    .map((month) => parseInt(watch(month)?.replace(".", "")))
    .filter((month) => !!month);

  const monthsFilled = monthsValues.filter((month) => !!month);

  const yearlyAverageConsumption =
    monthsFilled.reduce((acum, monthConsume) => acum + monthConsume, 0) /
    monthsFilled.length;

  const hasPdfFilledYearlyAverageConsumption =
    Number(averageConsumptionGlobal) > 0 || !!customer?.elegibilidade;

  const consumptionLessAvailability =
    Math.ceil(yearlyAverageConsumption - energyBoxAvailability[energyBox]) || 0;

  useEffect(() => {
    const options = generateDiscountOptions(
      state,
      powerCompany,
      averageConsumption
    );

    setDiscountOptions(options);

    if (
      discountFromUrl &&
      options?.some((item) => Number(item.value) === Number(discountFromUrl))
    ) {
      return setValue("desconto_cliente", discountFromUrl);
    }
  }, []);

  useEffect(() => {
    if (!discountOptions) {
      return setValue("desconto_cliente", "0");
    }

    if (discountOptions?.length === 1) {
      return setValue("desconto_cliente", discountOptions[0].value.toString());
    }
  }, [discountOptions]);

  useEffect(() => {
    if (averageConsumptionGlobal) return;

    setValue("consumomedio", consumptionLessAvailability);
  }, [averageConsumptionGlobal, consumptionLessAvailability]);

  useEffect(() => {
    const rangeValues = generateRangeValues(state, powerCompany);
    const { isAllowed, supplier } = calculateIfConsumptionIsAllowed(
      rangeValues,
      energyBoxAvailability[energyBox],
      averageConsumption
    );
    if (isAllowed && (!powerSupplier || powerSupplier !== supplier)) {
      setValue("fornecedora", supplier?.fornecedora);
      setValue("emissaocontrato", supplier?.emissaocontrato);
      setValue("needPassword", supplier?.exige_senhadistribuidora ?? false);
    }
  }, [powerCompany, state, energyBox, averageConsumption, powerSupplier]);

  return (
    <div className={styles.container}>
      <Title>
        Agora precisamos conhecer mais sobre o seu consumo de energia
      </Title>
      <Subtitle>
        Pegue a sua conta de luz e preencha as informações abaixo, se tiver
        alguma dúvida é só consultá-la.
      </Subtitle>
      <TextField label="Consumo médio anual" name="consumomedio" disabled />

      <input type="hidden" {...register("concessionaria")} />
      <input type="hidden" {...register("ufconsumo")} />
      <input type="hidden" {...register("energyBox")} />
      <input type="hidden" {...register("fornecedora")} />

      {!hasPdfFilledYearlyAverageConsumption && <LastYearEnergyConsumption />}
      {!!errors.consumomedio && (
        <ErrorMessage>Campos devem ser preenchidos.</ErrorMessage>
      )}

      {discountOptions?.length > 1 && !discountFromUrl && (
        <>
          <Divider />
          <Subtitle>
            Você tem acesso a opções de desconto disponíveis na adesão.
          </Subtitle>
          <FormLayout>
            <Text>Selecione o desconto desejado:</Text>
            <RadioGroup
              name="desconto_cliente"
              isRow={false}
              options={discountOptions}
            />
          </FormLayout>
        </>
      )}
    </div>
  );
}

function LastYearEnergyConsumption() {
  return (
    <div className={styles.monthsContainer}>
      {months.map((month) => (
        <TextField
          key={month}
          name={month}
          label={month}
          mask={Number}
          thousandsSeparator={"."}
          radix={"."}
        />
      ))}
    </div>
  );
}
