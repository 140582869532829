import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormLayoutInline = styled(Container)`
  flex-direction: row;
`
