import * as z from 'zod'

import {lessUnderscore} from "utils";

export const schemaAddressData = z.object({
  cep: z.preprocess(lessUnderscore, z.string().length(10, "CEP Obrigatório")),
  complemento: z.string().optional().default("Complemento obrigatório"),
  endereco: z.string().min(3, "Endereço obrigatório"),
  numero: z.string().min(1, "Número obrigatório"),
  bairro: z.string().min(1, "Bairro obrigatório"),
  cidade: z.string().min(3, "Cidade obrigatória"),
  uf: z.string().length(2, "UF obrigatório"),
})
