import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Select, TextField, Title } from "components";
import { useGetCepInfo } from "./api";

import { Container, FormLayoutInline } from "./styles";
import { useGlobalData } from "hooks";
import { states } from "./address-form.constants";

export function AddressForm() {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { customer } = useGlobalData();
  const { watch, reset, getValues } = useFormContext();
  const formData = watch(["nome", "cep", "endereco"]);
  const firstName = formData[0]?.split(" ")[0];
  const cep = formData[1];
  const { data } = useGetCepInfo({ cep });


  useEffect(() => {
    if (!data) return;

    const formattedCep = cep?.replace(/[^0-9]/g, '') || "";

    if (formattedCep.length < 8) return;

    reset({
      ...getValues(),
      endereco: data.logradouro,
      bairro: data.bairro,
      cidade: data.cidade,
      uf: data.uf
    })

    setShowForm(true);
  }, [
    data.logradouro,
    data.bairro,
    data.cidade,
    data.uf,
    cep,
    customer
  ]);

  return (
    <Container>
      <Title>{`Certo ${firstName}, agora nos diga qual é o seu endereço`}</Title>

      <TextField name="cep" label="CEP" mask="00.000-000" required />

      {showForm && (
        <>
          <TextField
            name="endereco"
            label="Logradouro"
            required
          />
          <FormLayoutInline>
            <TextField name="numero" label="Número" required />
            <TextField name="complemento" label="Complemento" />
          </FormLayoutInline>
          <TextField
            name="bairro"
            label="Bairro"
            required
          />
          <Select
            options={states}
            name="uf"
            label="Estado"
            required
          />
          <TextField
            name="cidade"
            label="Cidade"
            required
          />
        </>
      )}
    </Container>
  );
}
