import * as z from "zod";
import { calculateIfConsumptionIsAllowed } from "./utils";
import { ConfigOption } from "features/connection-form/ConnectionForm.types";

const energyBoxAvailability = {
  Monofásico: 30,
  Bifásico: 50,
  Trifásico: 100,
};

export const getSchemaEnergyCalcData = (configOptions: {
  getPowerLevelRangeByStateAndCompany: (
    state: string,
    company: string
  ) => ConfigOption[];
}) =>
  z
    .object({
      consumomedio: z
        .number()
        .min(1, "Campos de consumo devem ser preenchidos"),
      ufconsumo: z.string(),
      concessionaria: z.string(),
      fornecedora: z.string(),
      energyBox: z.string(),
      desconto_cliente: z
        .string()
        .min(1, "É necessário selecionar um desconto disponível"),
      emissaocontrato: z.boolean().optional(),
    })
    .superRefine((values, ctx) => {
      const range = configOptions.getPowerLevelRangeByStateAndCompany(
        values.ufconsumo,
        values.concessionaria
      );
      const energyBox = values.energyBox as
        | "Monofásico"
        | "Bifásico"
        | "Trifásico";
      const { isAllowed, minValue } = calculateIfConsumptionIsAllowed(
        range,
        energyBoxAvailability[energyBox],
        values.consumomedio
      );
      if (isAllowed) return true;
      ctx.addIssue({
        message: `Consumo médio não atingiu o valor mínimo de ${minValue}Kwh`,
        code: z.ZodIssueCode.custom,
        path: ["consumomedio"],
      });
    });
