import { useFormContext } from "react-hook-form";
import { TextField, Select, Title } from "components";
import { Container, FormLayout, FormLayoutInline, Text } from "./styles";
import { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { IconButton } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "assets";

const nacionalidade = [
  { value: "Brasileira(o)", label: "Brasileira(o)" },
  { value: "Estrangeira(o)", label: "Estrangeira(o)" },
];

const estadoCivis = [
  {
    label: "Casado(a)",
    value: "Casado(a)",
  },
  {
    label: "Solteiro(a)",
    value: "Solteiro(a)",
  },
  {
    label: "Viúvo(a)",
    value: "Viúvo(a)",
  },
  {
    label: "União Estável",
    value: "União Estável",
  },
  {
    label: "Divorciado(a)",
    value: "Divorciado(a)",
  },
];

const ExcludedAccentsRgx = /^[a-zA-Z0-9@._-]*$/;

export function PersonalInfoForm() {
  const [showFieldPassword, setShowFieldPassword] = useState<boolean>(false);
  const { watch, setError, clearErrors } = useFormContext();
  const [email, emailConfirm, needsPowerCompanyPassword] = watch([
    "email",
    "emailConfirm",
    "needPassword",
  ]);

  useEffect(() => {
    if (!_isEmpty(email) && email !== emailConfirm)
      return setError("emailConfirm", {
        message: "Email diferente do fornecido",
      });

    clearErrors("emailConfirm");
  }, [email, emailConfirm]);

  return (
    <Container>
      <Title>Insira os seus dados pessoais</Title>

      <FormLayout>
        <TextField name="cpf_cnpj" label="CPF" mask="000.000.000-00" required />
        <TextField name="nome" label="Nome completo" required />
        <TextField
          name="dtnasc"
          label="Data de nascimento"
          mask="00/00/0000"
          required
        />

        <FormLayoutInline>
          <TextField name="rg" label="RG" required />
          <TextField name="emissor" label="Órgão emissor" required />
        </FormLayoutInline>

        <TextField
          name="email"
          label="E-mail"
          type="email"
          mask={ExcludedAccentsRgx}
          required
        />

        <TextField
          name="emailConfirm"
          label="Confirme seu E-mail"
          mask={ExcludedAccentsRgx}
          onPaste={(e) => e.preventDefault()}
          required
        />
        <TextField
          name="celular"
          label="Celular"
          mask="(00) 00000-0000"
          required
        />
        <Select
          options={nacionalidade}
          name="nacionalidade"
          label="Nacionalidade"
          defaultValue={"Brasileira(o)"}
          required
        />
        <TextField name="profissao" label="Profissão" required />
        <Select
          options={estadoCivis}
          name="estadocivil"
          label="Estado Civil"
          required
        />
        {needsPowerCompanyPassword && (
          <FormLayout>
            <Text>Digite seu login e senha da distribuidora:</Text>
            <TextField name="logindistribuidora" label="Usuário" />
            <TextField
              name="senhadistribuidora"
              label="Senha"
              endAdornment={
                <IconButton
                  onClick={() =>
                    setShowFieldPassword((prevState) => !prevState)
                  }
                >
                  {showFieldPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </IconButton>
              }
              type={showFieldPassword ? "text" : "password"}
            />
          </FormLayout>
        )}
      </FormLayout>
    </Container>
  );
}
