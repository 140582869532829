import {
  imageBackground,
  SuccessIcon,
  GreenIcon,
  imageWorldEnergy,
} from "assets";
import { Banner, Subtitle, Title } from "components";
import { useMedia } from "utils";

import styles from "./styles.module.scss";

export function SentFormPage() {
  const isComputer = useMedia("computer");

  return (
    <div className={styles.startFormContainer}>
      <img className={styles.imageBackground} src={imageBackground} />

      <div className={styles.content}>
        <GreenIcon />

        <Title>
          Parabéns, sua jornada sustentável com a iGreen acabou de começar
        </Title>

        <Subtitle>
          Em breve você receberá via WhatsApp o seu contrato digital com todas
          as informações que precisa, quando ele chegar analise e assine o
          quanto antes que logo entraremos em contato quando sua solicitação
          estiver aprovada.
        </Subtitle>

        <Banner
          icon={isComputer && <SuccessIcon width={60} height={60} />}
          description="O contrato será enviado em seu WhatsApp em até 1 dia útil, caso tenha alguma dúvida entre em contato."
          color="#00B22A"
        />

        {isComputer && (
          <div className={styles.imageWorldEnergyContainer}>
            <img className={styles.imageWorldEnergy} src={imageWorldEnergy} />
          </div>
        )}

        {!isComputer && (
          <div className={styles.mobileFooter}>
            <div>
              <SuccessIcon width={60} height={60} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
