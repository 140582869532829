import * as z from "zod";

import { isValidCnpj, isValidDate, lessUnderscore } from "utils";
import {
  legalEntityWithConventionDocument,
  powerCompanyWithConventionDocument,
} from "./personalJuridicalForm.constants";
import _isEmpty from "lodash/isEmpty";

export const schemaPersonalJuridical = z
  .object({
    caminhoarquivocnpj: z.string().min(1, "Cartão CNPJ obrigatório"),
    cnpj: z.preprocess(
      lessUnderscore,
      z.string().min(1, "CNPJ obrigatório").refine(isValidCnpj, "CNPJ Inválido")
    ),
    razao: z
      .string({ required_error: "Razão Social obrigatório" })
      .min(5, "Razão Social obrigatório"),
    fantasia: z.string().min(3, "Nome Fantasia obrigatório"),
    dtabertura: z
      .string({ required_error: "Data abertura obrigatória" })
      .min(1, "Data abertura obrigatória"),
    telefone: z.string().min(14, "Telefone obrigatório"),
    naturezajuridica: z
      .string({
        required_error: "Natureza Jurídica obrigatória",
      })
      .min(1, "Natureza Jurídica obrigatória")
      .nullable()
      .transform((value, ctx) =>
        value === null
          ? ctx.addIssue({
              code: "custom",
              message: "Natureza Jurídica obrigatória",
            })
          : value
      ),
    nire: z.string().optional(),
    ie: z.string().optional(),
    caminhoarquivoestatutoconvencao: z.string().optional(),
    concessionaria: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (
      _isEmpty(values.caminhoarquivoestatutoconvencao) &&
      legalEntityWithConventionDocument.includes(
        values.naturezajuridica ?? ""
      ) &&
      powerCompanyWithConventionDocument.includes(values.concessionaria ?? "")
    )
      ctx.addIssue({
        message: "Estatuto e Convenção é obrigatório",
        code: z.ZodIssueCode.custom,
        path: ["caminhoarquivoestatutoconvencao"],
      });

    if (values.dtabertura && !isValidDate(values.dtabertura)) {
      ctx.addIssue({
        message: "Data inválida",
        code: z.ZodIssueCode.custom,
        path: ["dtabertura"],
      });
    }
  });
