import { useFormContext } from "react-hook-form";
import { FileUploaderInput, TextField, Title, AutoComplete } from "components";
import { Container, FormLayout, Text, ImageHint } from "./styles";
import { AttachmentIcon } from "assets";
import { useGlobalData } from "hooks/api";
import {
  legalEntityWithConventionDocument,
  naturezaJuridica,
  powerCompanyWithConventionDocument,
} from "./personalJuridicalForm.constants";
import { useEffect } from "react";
import { usePostAnyDocs } from "features/connection-form/api/post-AnyDocs";
import toast from "react-hot-toast";
import { compareTwoStrings } from "string-similarity";
import { POWER_COMPANIES_WITH_PROMPTS } from "../upload-energy-bill-form/constants";
import { formatName } from "utils";

const isCnpjInactive = (cnpjStatus: string) =>
  ["INATIVA", "BAIXADA"].includes(cnpjStatus);

const hasSimilarNames = (companyName1: string, companyName2: string) => {
  const formatedName =
    companyName1.length > 70 ? formatName(companyName1 ?? "") : companyName1;

  return (
    compareTwoStrings(
      formatedName?.toLowerCase(),
      companyName2?.toLowerCase()
    ) >= 0.3
  );
};

export function PersonalJuridicalForm() {
  const {
    accountType,
    isLoading,
    setIsLoading,
    customerIdFromEnergyBill,
    storedCnpjCard,
    setStoredCnpjCard,
  } = useGlobalData();

  const { watch, reset, getValues, resetField } = useFormContext();
  const { mutate: onAnalyzeDocs } = usePostAnyDocs();
  const [cnpjCard, cnpj, powerCompany, legalEntityType] = watch([
    "caminhoarquivocnpj",
    "cnpj",
    "concessionaria",
    "naturezajuridica",
  ]);
  const stateOfConsumption = watch("ufconsumo");

  const needConventionDocument =
    legalEntityWithConventionDocument.includes(legalEntityType) &&
    powerCompanyWithConventionDocument.includes(powerCompany);

  const isStateOfConsumptionEnableForAnalysis =
    POWER_COMPANIES_WITH_PROMPTS.includes(
      `${powerCompany}_${stateOfConsumption}`
    );
  const companyName = watch("razao");

  const displayErrorAndReset = (message: string) => {
    toast.error(message, {
      position: "bottom-center",
      duration: 10000,
    });
    resetField("caminhoarquivocnpj");
  };

  const handleSuccess = (data: any) => {
    if (isStateOfConsumptionEnableForAnalysis) {
      const inactiveCnpj = isCnpjInactive(data?.situacaoCadastral);
      const isTheSameCnpj = cnpj === customerIdFromEnergyBill;
      const isSimilarNames =
        hasSimilarNames(data?.razaoSocial, companyName) ||
        hasSimilarNames(data?.nomeFantasia, companyName);
      const isTheSameCnpjFromForm = cnpj === data?.cnpj;

      if (inactiveCnpj)
        return displayErrorAndReset(
          `O Cnpj possui situação cadastral ${data?.situacaoCadastral}`
        );
      if (!isSimilarNames && !isTheSameCnpj)
        return displayErrorAndReset(
          "O documento anexado possui divergências comparado às informações da conta de energia."
        );
      if (!isTheSameCnpjFromForm)
        return displayErrorAndReset(
          "O documento anexado possui o CNPJ divergente do preenchido no formulário."
        );
    }

    reset({
      ...getValues(),
      cnpj: data?.cnpj,
      razao: data?.razaoSocial,
      fantasia: data?.nomeFantasia,
      dtabertura: data?.dataAbertura,
      telefone: data?.telefone,
      naturezajuridica: (
        !!data.naturezaJuridica && data.naturezaJuridica
      ).toUpperCase(),
      ie: data?.inscricaoEstadual,
      emp_cep: data?.endereco.cep,
      emp_numero: data?.endereco.numero,
      emp_complemento: data?.endereco.complemento,
      emp_bairro: data?.endereco.bairro,
      emp_uf: data?.endereco.estado,
      emp_cidade: data?.endereco.cidade,
      emp_endereco: data?.endereco.rua,
    });

    setStoredCnpjCard(cnpjCard);
    toast.success("Documentos analisados com sucesso!", {
      position: "bottom-center",
    });
  };

  const handleError = (error: any) => {
    console.error(error);
    displayErrorAndReset(
      "Arquivos inválidos. Certifique-se que anexou documentos válidos e legíveis. Tente novamente!"
    );
  };

  useEffect(() => {
    if (!cnpjCard || storedCnpjCard === cnpjCard) return;
    setIsLoading(true);
    onAnalyzeDocs(
      {
        type: "cnpjCard",
        file: cnpjCard?.split("base64,")[1],
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled: () => setIsLoading(false),
      }
    );
  }, [cnpjCard]);

  return (
    <Container>
      <Title>Agora você precisa nos enviar os dados do seu CNPJ</Title>

      <FormLayout>
        <Text>Para começar, anexe o seu cartão Cnpj:</Text>
        <FileUploaderInput
          name="caminhoarquivocnpj"
          icon={<AttachmentIcon />}
          description="Anexar cartão CNPJ"
          accept="image/*,.pdf"
        />
        <ImageHint>
          Aceitamos apenas arquivos em formato PDF, PNG e JPG<span>*</span>
        </ImageHint>
      </FormLayout>

      {cnpjCard?.length > 0 && !isLoading && (
        <>
          <TextField name="concessionaria" type="hidden" label="" />
          <TextField
            name="cnpj"
            label="CNPJ"
            mask="00.000.000/0000-00"
            required
            disabled={!!cnpj?.length && accountType === "PJ"}
          />
          <TextField name="razao" label="Razão social" required />
          <TextField name="fantasia" label="Nome fantasia" required />
          <TextField
            name="dtabertura"
            label="Data Abertura"
            mask="00/00/0000"
            required
          />
          <TextField
            name="telefone"
            label="Telefone"
            mask={[
              {
                mask: "(00) 0000-0000",
              },
              {
                mask: "(00) 00000-0000",
              },
            ]}
            required
          />
          <AutoComplete
            options={naturezaJuridica}
            name="naturezajuridica"
            label="Natureza Jurídica"
            required
          />
          <TextField name="nire" label="Nire/Cartório" />
          <TextField name="ie" label="Inscricao Estadual" />
          {needConventionDocument && (
            <>
              <Text>
                Para continuar, anexe o seu documento de estatuto e convenção:
              </Text>
              <FileUploaderInput
                name="caminhoarquivoestatutoconvencao"
                icon={<AttachmentIcon />}
                description="Estatuto e convenção"
                accept="image/*,.pdf"
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}
