import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormLayout = styled(Container)``

export const FormLayoutInline = styled(FormLayout)`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: "Nexa Bold";
  font-size: 16px;
  font-weight: 700;
`;