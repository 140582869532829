import * as z from 'zod'

export const schemaEnergyProviderData = z.object({
  concessionaria: z.string().min(1, 'Distribuidora de Energia obrigatória'),
  classificacao: z.string().min(1, 'Tipo de instalação obrigatório'),
  energyBox: z.string().min(1, 'Tipo de padrão obrigatório'),
  valorkwh: z
    .string()
    .min(3, 'Valor do Kwh obrigatório')
    .transform((value) => Number(value)),
  numcliente: z.string().min(3, 'Número do cliente obrigatório'),
  valorcontribuicao: z
    .string()
    .transform((value) => Number(value)),
  numinstalacao: z.string().min(3, 'Número de instalação obrigatório'),
}).superRefine((values, ctx) => {
  if (values.classificacao !== 'Rural' && values.valorcontribuicao < 3) {
    ctx.addIssue({
      message: 'Taxa de iluminação pública obrigatória',
      code: z.ZodIssueCode.custom,
      path: ['valorcontribuicao'],
    });
  }
}) as any 