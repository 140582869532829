type DataRecord = Record<string, any>;

export const energyCalcFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => {
    const partData = initialData?.classificacao.split(" - ");
    const energyBox = partData[1];

    if (key === "energyBox") {
      return currentData[key] || energyBox || "";
    }

    if (key === "desconto_cliente") {
      return currentData[key] || initialData[key] || 0;
    }

    return currentData[key] || initialData[key] || "";
  };

  return {
    consumomedio: getDefaultValue("consumomedio"),
    ufconsumo: getDefaultValue("ufconsumo"),
    concessionaria: getDefaultValue("concessionaria"),
    fornecedora: getDefaultValue("fornecedora"),
    energyBox: getDefaultValue("energyBox"),
    desconto_cliente: getDefaultValue("desconto_cliente"),
    emissaocontrato: true,
    needPassword: false,
  };
};
