import { Select, TextField, Title } from 'components'

import { Container, FormLayoutInline } from './styles'
import { states } from '../address-form/address-form.constants'

export function CompanyAddressForm() {
  return (
    <Container>
      <Title>
        Certo para finalizar agora nos diga qual é o seu endereço da sua
        empresa.
      </Title>

      <TextField
        name="emp_cep"
        label="CEP"
        mask="00.000-000"
        required
      />

      <TextField
        name="emp_endereco"
        label="Endereço"
        required
      />
      <FormLayoutInline>
        <TextField
          name="emp_numero"
          label="Número"
          required
        />
        <TextField
          name="emp_complemento"
          label="Complemento"
        />
      </FormLayoutInline>
      <TextField
        name="emp_bairro"
        label="Bairro"
        required
      />
      <Select
        options={states}
        name="emp_uf"
        label="Estado"
        required
      />
      <TextField name="emp_cidade" label="Cidade" required />
    </Container>
  );
}
